<template>
    <div class="member-detail-banner-box">
        <img :src="require('@/assets/images/lensman/fa4.jpg')" alt="">
        <div class="member-detail-banner">
            <div class="container">
                <!-- 左侧 -->
                <div class="member-detail-banner-lf">
                    <span class="member-detail-banner-lf-evaluate">
                        <i class="el-icon-star-on"></i>
                        {{personal_data.score}}
                    </span>
                    <span>
                        已完成{{personal_data.orders}}单
                    </span>
                    <span v-if="personal_data.identity && personal_data.identity.includes(0)">
                        摄影师
                    </span>
                </div>
                <!-- 右侧 -->
                <div class="member-detail-banner-rt">
                    <span v-if="personal_data.identity && personal_data.identity.includes(1)">化妆师</span>
                    <span v-if="personal_data.identity && personal_data.identity.includes(2)">斜杠青年</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: mapState({
        personal_data: state => state.goodsDetail.personal_data,
    }),
}
</script>

<style scoped>
.member-detail-banner-box{
    position: relative;
    height: 400px;
}
.member-detail-banner-box>img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit:cover;
}
.member-detail-banner{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
/* 左侧 */
.member-detail-banner-lf{
    float: left;
}
.member-detail-banner-lf-evaluate>i{
    font-size: 18px;
    color: #ffd86b;
}
/* 右侧 */
.member-detail-banner-rt{
    float: right;
}
/* 公共属性 */
.member-detail-banner-rt>span,
.member-detail-banner-lf>span{
    display: inline-block;
    vertical-align: middle;
    padding: 7px 30px;
    background-color: #8d9afc;
    font-size: 18px;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.member-detail-banner-rt>span+span,
.member-detail-banner-lf>span+span{
    margin-left: 10px;
}
</style>